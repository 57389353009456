<template>
    <div id="contents" class="contents vmd_channel page">
        <div class="content_head">
            <!-- s: 페이지 타이틀 -->
            <div class="page_ttl_wrap has_btn">
                <h2 class="page_ttl">채널 관리</h2>
                <div class="btn_group">
                    <span>최근 발행일시 {{recentPublishTime ? recentPublishTime : '없음'}}</span>
                    <!-- Dev: 변경사항이 없을시 비활성화 -->
                    <button type="button" class="btn strong2 w_m h_l" @click="publish">발행하기</button>
                </div>
            </div>
            <!-- e: 페이지 타이틀 -->
        </div>
        <!-- s: content_body -->
        <div class="content_body">
            <!-- s: input_sec -->
            <div class="input_sec">
                <!-- s: table -->
                <div class="tbl_wrap list form">
                    <table class="tbl">
                        <caption></caption>
                        <tbody>
                        <tr>
                            <th class="">전시 시간</th>
                            <td class="">
                                <div class="input_area select inline">
                                    <select v-model.number="startHour">
                                        <option v-for="(time) in 24" :value="time-1">{{ time - 1 }}</option>
                                    </select>
                                    <div class="input_txt">시 부터</div>
                                </div>
                                <div class="input_area select inline">
                                    <select v-model.number="endHour">
                                        <option v-for="(time) in 24" :value="time">{{ time }}</option>
                                    </select>
                                    <div class="input_txt">시 까지</div>
                                </div>
                                <div class="btn_area inline">
                                    <button @click="saveExhibitHour" type="button" class="btn strong h_l w_sm">저장
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <!-- e: table -->
            </div>
            <!-- e: input_sec -->

            <!-- s: channel_list_sec-->
            <div class="channel_list_sec">
                <div class="channel_list_area" v-for="(item, hash) in channels" :key="'channel-' + hash"
                     @click.prevent="editChannel($event, item, hash)">
                    <div class="channel_list_type">
                        <p>{{ getChannelType(item) }}</p>
                    </div>
                    <div class="channel_list_num">
                        <h3>채널 {{ item.no ? item.no : 0 }}</h3>
                    </div>
                    <div class="channel_list_info">
                        <p>{{ item.name }}</p>
                    </div>
                    <!-- Dev : hover시 삭제 버튼 노출 -->
                    <div class="delete_btn_area">
                        <button @click.prevent="removeList(hash)">삭제</button>
                    </div>
                </div>
                <!-- Dev: 채널 추가 영역  -->
                <div class="channel_list_area none" role="button" @click="addList()">
                    <div class="channel_list_add">
                        <h3>채널 추가 <i></i></h3>
                    </div>
                </div>
            </div>
            <!-- e: channel_list_sec-->
        </div>
        <!-- e: content_body -->
    </div>
    <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false"
            @yesDialog="deleteVmd()">
        <template #body>
            <h3>삭제 확인</h3>
            <p>선택한 카드를 삭제하시겠습니까?<br>
                변경된 내용은 “발행하기”를 진행해야 체험존에 반영됩니다</p>
        </template>
    </Dialog>

    <!-- Dev :  채널 추가 선택시 -->
    <Dialog :isOpen="channelAddDialogState" :btn-group="false" v-on:cancelDialog="channelAddDialogState = false" :channelModal="true">
        <template #body>
            <h3>채널 타입</h3>
            <ul class="channel_list">
                <li v-for="(item, key) in templateTypes">
                    <button @click="createChannel(item)">{{item.name}}</button>
                </li>
            </ul>
        </template>
    </Dialog>
</template>

<script>
import moment from "moment";
import Dialog from "@/components/dialog";
import {TEMPLATE} from "@/plugins/firebase/type";

export default {
    name: "index",
    layout: {
        name: 'new-admin',
    },
    components: {
        Dialog,
    },
    data() {
        return {
            moment: moment,
            startHour: 0,
            endHour: 24,
            vmd_root_key: 'vmd',
            channel_root_key: 'channel',
            channel_setting_key: 'setting/channel',
            channel_publish_key: 'publish/channel',
            recentPublishTime: '',
            channels: {},
            modifyChannel: '',
            deleteDialogState: false,
            channelAddDialogState: false,
            templateTypes: TEMPLATE,
            clickedChannelHash: null,
            canLeaveSite: true,
        }
    },
    methods: {
        async saveExhibitHour() {
            //startHour, endHour 저장.
            if (this.startHour === this.endHour) {
                this.toastDanger('같은 시간을 설정할 수 없습니다.');
                return;
            }
            if (this.startHour > this.endHour) {
                this.toastDanger('시작시간이 종료시간보다 앞에 존재할 수 없습니다.');
                return;
            }
            let chSetting = {
                exhibitStartHour: this.startHour,
                exhibitEndHour: this.endHour
            }
            await this.$firebase.database().ref(this.channel_setting_key).set(chSetting);
            //await this.$firebase.database().ref(this.channel_setting_key).child('exhibitEndHour').set(this.endHour);
            this.toastSuccess('전시 시간 저장 완료.');
        },
        async publish() {
            const time = this.timeToString(moment());
            await this.$firebase.database().ref(this.channel_publish_key).child('lastPublishDateTime').set(time);
            this.toastSuccess('발행 완료.');
        },
        async deleteVmd() {
            this.deleteDialogState = false;
            // 2022-01-25 파일 삭제 임시 주석
            //await this.deleteFolderContents(this.clickedChannelHash);
            this.$firebase.database().ref(this.channel_root_key).child(this.clickedChannelHash).remove().then(async () => {
                this.clickedChannelHash = null;
                this.$firebase.database().ref(this.channel_root_key).once('value', (sn) => {
                    let channels = sn.val() || {};
                    let channelArr = [];
                    for(const [hash, channel] of Object.entries(this.channels)){
                        channel.hash = hash;
                        channelArr.push(channel);
                    }
                    channelArr = channelArr.sort((a, b) => {
                        return Number(a.no) - Number(b.no);
                    })
                    channelArr.forEach((item, index) => {
                        item.no = index + 1;
                        channels[item.hash] = item;
                    });
                    this.$firebase.database().ref(this.channel_root_key).set(channels).then(() => {
                        this.$firebase.database().ref(this.channel_root_key).once('value', (sn) => {
                            this.channels = sn.val() || {};
                            this.toastSuccess('채널이 삭제 되었습니다.');
                        });
                    });

                })
            });
            this.canLeaveSite = false;
            // this.redirect('/vmd');
        },
        deleteFolderContents(path) {
            const ref = this.$firebase.storage().ref(path);
            return ref.listAll()
                    .then(dir => {
                        dir.items.forEach(fileRef => {
                            this.deleteFile(ref.fullPath, fileRef.name);
                        });
                        dir.prefixes.forEach(folderRef => {
                            this.deleteFolderContents(folderRef.fullPath);
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    });
        },
        deleteFile(pathToFile, fileName) {
            const ref = this.$firebase.storage().ref(pathToFile);
            const childRef = ref.child(fileName);
            childRef.delete();
            this.canLeaveSite = false;
        },
        removeList(hash) {
            this.clickedChannelHash = hash;
            this.deleteDialogState = true;
        },
        addList() {
            this.channelAddDialogState = true;
        },
        getChannelType(item){
            switch (item.templateType){
                case this.templateTypes.HOT_DEAL.code:
                    return this.templateTypes.HOT_DEAL.name;
                    break;
                case this.templateTypes.HALF_PRICE.code:
                    return this.templateTypes.HALF_PRICE.name;
                    break;
                case this.templateTypes.EXPLORE.code:
                    return this.templateTypes.EXPLORE.name;
                    break;
            }
          return '';
        },
        editChannel(event, item, hash){
            const tn = event.target.tagName;
            if(tn === 'DIV'){
                this.redirect('/channel/edit/' + hash);
            }
        },
        createChannel(template){
            this.redirect('/channel/create/' + template.code);
        }
    },
    created() {
        this.$firebase.database().ref(this.channel_publish_key).on('value', (sn) => {
            const publish = sn.val() || {};
            if (publish) {
                this.recentPublishTime = publish.lastPublishDateTime;
            }
        });

        this.$firebase.database().ref(this.channel_setting_key).on('value', (sn) => {
            let settings = sn.val();
            if(settings){
                if(settings.exhibitStartHour){
                    this.startHour = settings.exhibitStartHour;
                }
                if(settings.exhibitEndHour){
                    this.endHour = settings.exhibitEndHour;
                }
            }
        })

        this.$firebase.database().ref(this.vmd_root_key).on('value', (sn) => {
            this.vmd = sn.val() || {};
        })
        this.$firebase.database().ref(this.channel_root_key).on('value', (sn) => {
            this.channels = sn.val() || {};
        })
    },
    mounted() {
        window.addEventListener('beforeunload', this.unLoadEvent);
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.unLoadEvent);
    },
    beforeRouteLeave(to, from, next) {
        if (this.canLeaveSite) next();
        else if (confirm('이 사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.')) {
            next();
        }
    }
}
</script>

<style scoped>

</style>
